import Parse from 'parse'
import Api from './Api'
//import {_} from 'vue-underscore';


function generateRandomString(length) {
    let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
}

export const ClientService = {

    getMasterAgencies(context) {

        let curUser = Parse.User.current();

        let self = this;
        let companies = Parse.Object.extend('Company');
        let query = new Parse.Query(companies);

        let compPointer = {"__type": "Pointer", "className": "Company", "objectId":  curUser.attributes.company.id}
        query.equalTo('clientOf', compPointer);
        query.equalTo('isAgency', true);

        query.ascending('name');

        return new Promise((resolve, reject) => {

            query.find().then(response => {
                context.commit('setMasterAgencies', response);
                resolve(response)
            }).catch(error => {
                self.$checkTokenExp(error);
                reject(error);
            })

        });

    },

    getAdminClients(context) {

        let self = this;
        let curCompany = context.state.userCompany;

        let companies = Parse.Object.extend('Company');
        let query = new Parse.Query(companies);

        query.include('clientOf');
        query.limit(1000);

        if(curCompany.attributes && curCompany.attributes.relatedCompanies){
            query.containedIn('clientOf', curCompany.attributes.relatedCompanies);
        }

        return new Promise((resolve, reject) => {

            query.find().then(response => {

                context.commit('setAgencyClients', response);
                resolve(response)
            }).catch(error => {
                self.$checkTokenExp(error);
                reject(error);
            })

        });

    },

    getAllAgencies(context, data) {

        let companies = Parse.Object.extend('Company');
        let query = new Parse.Query(companies);
        query.equalTo('isAgency', true);
        query.limit(1000);

        if(data && data.sortBy){
            query.descending(data.sortBy);
        }else {
            query.ascending('name');
        }

        query.include('relatedClients');
        return new Promise((resolve, reject) => {
            query.find().then(response => {
                context.commit('setMasterAgencies', response);
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });
    },

    setImpersonatingAgency(context, data) {
        let curUser = Parse.User.current();
        let agencyPointer = {"__type": "Pointer", "className": "Company", "objectId":  data.agencyId}
        curUser.set('impersonatingAgency', agencyPointer);
        return new Promise((resolve, reject) => {
            curUser.save().then(response => {
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    },

    getClientsOfAgency(context, data) {
        let self = this;
        let companies = Parse.Object.extend('Company');
        let query = new Parse.Query(companies);
        let compPointer = {"__type": "Pointer", "className": "Company", "objectId":  data.agencyId}
        query.equalTo('clientOf', compPointer);
        query.ascending('name');
        query.include('usedPromo');
        query.include('relatedCardConfig');
        query.limit(1000);


        if(data && data.searchTerm){
            //Using search
            let regEx = '.*'+data.searchTerm+".*";

            let compNameQuery = new Parse.Query(companies);
            compNameQuery.matches('name', regEx, 'i');

            let vatQuery = new Parse.Query(companies);
            vatQuery.matches('vatNumber', regEx, 'i');

            let websiteQuery = new Parse.Query(companies);
            websiteQuery.matches('website', regEx, 'i');

            let combinedOrQueries = Parse.Query.or(compNameQuery, vatQuery, websiteQuery);

            let finalQuery = Parse.Query.and(query, combinedOrQueries)

            return new Promise((resolve, reject) => {
                finalQuery.find().then(response => {
                    context.commit('setPrivateAgencyClients', response);
                    context.commit('setAgencyClients', response);
                    resolve(response);
                }).catch(error => {
                    self.$checkTokenExp(error)
                    reject(error);
                })
            });

            //query.contains('lastName', filters.searchTerm);
            //query.contains('login', filters.searchTerm);
        } else {

            return new Promise((resolve, reject) => {
                query.find().then(response => {
                    context.commit('setPrivateAgencyClients', response);
                    context.commit('setAgencyClients', response);
                    resolve(response);
                }).catch(error => {
                    self.$checkTokenExp(error);
                    reject(error);
                })
            });
        }


    },


    getAgencyClients(context, filters = null) {

        let self = this;
        let companies = Parse.Object.extend('Company');
        let query = new Parse.Query(companies);

        query.include('usedPromo');
        query.include('relatedCardConfig');
        query.limit(1000);

        let curUser = Parse.User.current();

        if(filters && filters.clientOf){
            //When navigating to see the clients of a specific Agency
            query.equalTo('clientOf', filters.clientOf);

        }else {
            //Default list which just fetches the clients of the current agency
            let compPointer = {"__type": "Pointer", "className": "Company", "objectId":  curUser.attributes.company.id}
            query.equalTo('clientOf', compPointer);
        }

        // console.log("Fetching companies for parent company: ", query)

        query.ascending('name');

        let employees = Parse.Object.extend('Employee');
        let relatedEmployees = new Parse.Query(employees);
        relatedEmployees.matchesQuery('company', query);

        if(filters && filters.searchTerm){
            debugger;
            //Using search
            let regEx = '.*'+filters.searchTerm+".*";

            let compNameQuery =new Parse.Query(companies);
            compNameQuery.matches('name', regEx, 'i');

            let vatQuery = new Parse.Query(companies);
            vatQuery.matches('vatNumber', regEx, 'i');

            let websiteQuery = new Parse.Query(companies);
            websiteQuery.matches('website', regEx, 'i');

            let combinedOrQueries = Parse.Query.or(compNameQuery, vatQuery, websiteQuery);

            let finalQuery = Parse.Query.and(query, combinedOrQueries)

            return new Promise((resolve, reject) => {
                finalQuery.find().then(response => {
                    context.commit('setAgencyClients', response);
                    resolve(response)
                }).catch(error => {
                    self.$checkTokenExp(error)
                    reject(error);
                })
            });

            //query.contains('lastName', filters.searchTerm);
            //query.contains('login', filters.searchTerm);
        }
        else {
            //Not using search
            return new Promise((resolve, reject) => {

                    query.find().then(response => {
                        //debugger;
                        context.commit('setAgencyClients', response);
                        context.commit('setPrivateAgencyClients', response);

                        resolve(response)
                    }).catch(error => {
                        self.$checkTokenExp(error);
                        reject(error);
                    })

                });


        }

    },

    changeImpersonation(context, data) {
        let pointer = {"__type": "Pointer", "className": "Company", "objectId": data.id}

        let saver = data;
        if(context.state.userCompany){
            saver = context.state.userCompany;
        }
        saver.set('impersonating', pointer);
        return new Promise((resolve, reject) => {
            saver.save().then(response => {
                context.dispatch('getCardConfig');
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        });
    },

    selectCard(context, selectedAddressId){
        let selected = context.state.officeAddresses.find(obj => {
            if(obj.id === selectedAddressId){
                return obj;
            }else{
                return false;
            }
        });
        return selected;
    },

    saveClientApi(context, data) {
        let formData = new FormData();

        let info = {
            company: data.form,
            office: data.office,
            template: data.template,
            promo: data.promo,
            agencyId: data.agencyId,
            isAgency: data.isAgency
        };

        formData.append('action', 'createClient');

        //FILES
        formData.append('imgLogo', data.imgFiles.imgLogo);
        formData.append('cardFront', data.imgFiles.imgCardScanFront);
        formData.append('cardBack', data.imgFiles.imgCardScanBack);

        //DATA
        formData.append('data',  JSON.stringify(info));

        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData, ).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });

    },

    sendApprovedAgencyEmail(context, data) {

        let formData = new FormData();
        formData.append('action', 'sendApprovedAgencyEmail');

        let info = {
            agencyName: data.agency.attributes.name,
            agencyEmail: data.agency.attributes.billingEmail,
            vatNumber: data.agency.attributes.vatNumber
        }

        formData.append('data',  JSON.stringify(info));

        Api().post('helpers/api.php', formData, );
    },


    uploadAgencyFiles(context, data) {

        context.dispatch('getCompany').then(response => {
            let curCompany = response;

            let panelFile = new Parse.File(data.panelFile.name, data.panelFile);
            let contractFile = new Parse.File(data.signedFile.name, data.signedFile);

            curCompany.set('agencyPanelLogo', panelFile);
            curCompany.set('agencyContract', contractFile);

            return new Promise((resolve, reject) => {
                curCompany.save().then(response => {
                    resolve(response);
                    context.dispatch('getCompany');


                    let info = {
                        agencyId: response.id,
                        agencyName: response.attributes.name,
                        agencyEmail: response.attributes.billingEmail,
                        vatNumber: response.attributes.vatNumber
                    }

                    let formData = new FormData();
                    formData.append('action', 'sendContractEmails');

                    //FILES
                    formData.append('agencyPanelLogo', data.panelFile);
                    formData.append('agencyContract', data.signedFile);
                    //DATA
                    formData.append('data',  JSON.stringify(info));

                    Api().post('helpers/api.php', formData, );

                }).catch(err => {
                    reject(err);
                });
            });
        });



    },


    saveAdminClient(context, data) {
        if (data.id !== null) {
            let dataSet = 'agencyClients';
            if(data.dataSet) {
                dataSet = data.dataSet;
            }
            let selectedCompany = context.getters.selectedModalData(dataSet);

            return new Promise((resolve, reject) => {

                selectedCompany.set('clientOf', data.clientOf);
                selectedCompany.set('canUpgrade', data.canUpgrade);

                if(data.canUpgrade === false){
                    selectedCompany.set('contractRequested', null);
                }

                if(data.isAgency) {
                    selectedCompany.set('lightLogoBg', data.lightLogoBg);
                }

                if(typeof (data.isAgency) !== 'undefined') {
                    selectedCompany.set('isAgency', data.isAgency);

                    //Find pricing tier for this company and update to agency pricing
                    let PricingTier = Parse.Object.extend('PricingTier');
                    let priceQuery = new Parse.Query(PricingTier);
                    let compPointer = {"__type": "Pointer", "className": "Company", "objectId":  data.id}
                    priceQuery.equalTo('relatedCompany', compPointer);

                    priceQuery.first().then(priceTier => {
                        if(data.isAgency){
                            priceTier.set('pricingTier1', 2.40);
                            priceTier.set('pricingTier2', 2.24);
                            priceTier.set('pricingTier3', 2.08);
                            priceTier.set('pricingTier4', 1.92);
                            priceTier.set('pricingTier5', 1.76);
                        } else {
                            priceTier.set('pricingTier1', 3.00);
                            priceTier.set('pricingTier2', 2.80);
                            priceTier.set('pricingTier3', 2.60);
                            priceTier.set('pricingTier4', 2.40);
                            priceTier.set('pricingTier5', 2.20);
                        }

                        priceTier.save();
                    });

                }

                if (data.logo !== undefined && data.logo.file && data.isAgency) {
                    let file = new Parse.File(data.logo.name, data.logo.file);
                    selectedCompany.set('agencyPanelLogo', file);
                }

                if(!selectedCompany.attributes.frameSecret){
                    selectedCompany.set('frameSecret', generateRandomString(20));
                }

                if(data.verified && (!selectedCompany.attributes.verified || typeof(selectedCompany.attributes.verified) == 'undefined')){
                    context.dispatch('sendApprovedAgencyEmail', {agency: selectedCompany});
                    selectedCompany.set('verifiedOn', new Date());
                } else if( data.verified == false ) {
                    selectedCompany.set('verifiedOn', null);
                }

                selectedCompany.set('verified', data.verified ? data.verified : false);

                if(data.hasTrial === false){
                    selectedCompany.set('trialUntil', null);
                }else if(data.trialUntil){
                    selectedCompany.set('trialUntil', new Date(data.trialUntil));
                }

                selectedCompany.save().then(response => {
                    context.dispatch('getAllAgencies');
                    context.dispatch('getClientsOfAgency', {agencyId: context.state.agencyId});

                    resolve(response);
                }).catch(err => {
                    reject(err);
                })
            })
        }

    },

    saveClient(context, data) {
        //let parentAgency = { "__type": "Pointer", "className": "Company", "objectId": context.state.userCompany }

        if (data.id === null) {
            let newClient = Parse.Object.extend('Company');
            let clientRecord = new newClient();

            return new Promise((resolve, reject) => {
                clientRecord.set('name', data.name);
                clientRecord.set('vatNumber', data.vatNumber);
                clientRecord.set('billingAddress', data.billingAddress);
                clientRecord.set('responsible', data.responsible);
                clientRecord.set('telephone', data.telephone);
                clientRecord.set('billingEmail', data.billingEmail);
                clientRecord.set('website', data.website);
                clientRecord.set('canUpgrade', data.canUpgrade);
                /* clientRecord.set('frameSecret', generateRandomString(20));

                if(typeof (data.isAgency) !== 'undefined') {
                    clientRecord.set('isAgency', data.isAgency);
                } else {
                    clientRecord.set('isAgency', false);
                }

                if(data.hasTrial === false){
                    clientRecord.set('trialUntil', null);
                }else if(data.trialUntil){
                    clientRecord.set('trialUntil', new Date(data.trialUntil));
                }

                // if(context.state.userCompany){
                //     clientRecord.set('clientOf', context.state.userCompany);
                // }

                if (data.logo && data.logo.name) {
                    let file = new Parse.File(data.logo.name, data.logo);
                    clientRecord.set('logo', file);
                } else {
                    var xhr = new XMLHttpRequest();
                    xhr.open("GET", '/img/icon.png', true);
                    xhr.responseType = "blob";
                    xhr.onload = function () {
                        //console.log(this.response);
                        let reader = new FileReader();
                        reader.onload = function() {
                            //let res = event.target.result;
                            //console.log("Res" , res, e);
                        }
                        let file = this.response;
                        let parseFile = new Parse.File('card-icon.png', file);
                        clientRecord.set('logo', parseFile);
                        reader.readAsDataURL(file);
                        //debugger;
                    };
                    xhr.send()

                }
                */

                clientRecord.save().then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });

            });
        } else {
            return new Promise((resolve, reject) => {

                let source = data.dataSet ? data.dataSet : 'agencyClients';
                let companyObj = context.getters.selectedModalData(source);

                companyObj.set('name', data.name);
                companyObj.set('vatNumber', data.vatNumber);
                companyObj.set('billingAddress', data.billingAddress);
                companyObj.set('responsible', data.responsible);
                companyObj.set('telephone', data.telephone);
                companyObj.set('billingEmail', data.billingEmail);
                //context.getters.selectedModalData('employeeCards').set('address', data.address);
                companyObj.set('telephone', data.telephone);
                companyObj.set('canUpgrade', data.canUpgrade);

                if(data.canUpgrade === false){
                    companyObj.set('contractRequested', null);
                }

                if(!context.getters.selectedModalData(source).attributes.frameSecret){
                    context.getters.selectedModalData(source).set('frameSecret', generateRandomString(20));
                }

                context.getters.selectedModalData(source).save().then(response => {

                    if(source === 'masterAgencies'){
                        context.dispatch('getAllAgencies');
                    }else {
                        context.dispatch('getAgencyClients');
                    }
                    resolve(response);
                }).catch(err => {
                    reject(err);
                })


            })
        }
    },

    sendCalendlyEmail(context, data){
        let formData = new FormData();
        formData.append('action', 'sendCalendlyLink');
        formData.append('companyId', data.id);
        formData.append('calendlyEmail', data.calendlyEmail);

        formData.append('data', JSON.stringify({
            companyId: data.id,
            calendlyEmail:data.calendlyEmail
        }));

        return new Promise((resolve, reject) => {
            Api().post('/helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },


    createCardConfig(context, data){
        let template = Parse.Object.extend('CardTemplate');
        let cardConfig = Parse.Object.extend('CardConfig');

        let newConfig = new cardConfig;
        let query = new Parse.Query(template);
        query.equalTo('objectId', data.cardTemplate);

        query.first().then(response => {

            let defaults = response.attributes.availableParams;

            defaults.forEach(function(setting){
                if(setting.fieldType !== "DropzoneField"){
                    let infoToSave = data[setting.field];

                    if(!infoToSave) {
                        infoToSave = setting.defaultValue;
                    }
                    if(setting.fieldType === "SliderField"){
                        infoToSave = parseInt(data[setting.field]);
                    }
                    newConfig.set(setting.field, infoToSave);

                }
            });

            // let fileLogo = new Parse.File(data.imgLogo.name, data.imgLogo);
            // clientRecord.set('imgLogo', fileLogo);
            for (const k in data.imgFiles){
                if(data.imgFiles[k] !== null){

                    let file = new Parse.File(data.imgFiles[k].name, data.imgFiles[k]);
                    newConfig.set(k, file);
                }
            }

            if(data.appIcon){
                let appIconFile = new Parse.File(data.appIcon.name, data.appIcon);
                newConfig.set('appIcon', appIconFile);
            }

            let compPointer = {"__type": "Pointer", "className": "Company", "objectId": data.officeAddress.compId ? data.officeAddress.compId : context.state.cardCompany.id}
            let templatePointer = {"__type": "Pointer", "className": "CardTemplate", "objectId": data.cardTemplate}

            if(data.fontFamily){
                let fontFamilyPointer = {"__type": "Pointer", "className": "FontFamily", "objectId": data.fontFamily}
                newConfig.set('relatedFont', fontFamilyPointer);
            }

            if(data.baseFontSize) {
                newConfig.set('baseFontSize', parseInt(data.baseFontSize));
            }

            newConfig.set('company', compPointer);
            newConfig.set('template', templatePointer);
            newConfig.set('designVer', 1);

            return new Promise((resolve, reject) => {
                newConfig.save().then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
            });
        });

    },
    getClientStats(context, data){
        return new Promise((resolve, reject) => {
            // let query = new Parse.Query('Stats');
            //
            // query.containedIn('relatedCompany', data);
            // query.limit(5000);

            let query = new Parse.Query('MonthlyStats');
            query.containedIn('relatedCompany', data);
            query.ascending('month');

            query.find().then(response => {
                // console.log("Response: ", response);
                resolve(response);
            }).catch(err => {
                reject(err);
            });

        })
    },
    getAllAgencyStats(context, data = {}){
        return new Promise((resolve, reject) => {
            let allMonths = data.months ? data.months : [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];
            let year = data.year ? data.year : null;
            let companies = data.companies ? data.companies : null;
            //console.log('agencyClients', companies)
            let query = new Parse.Query('MonthlyStats');

            query.containedIn('month', allMonths);
            if(year){
                query.equalTo('year', parseInt(year));
            }
            if(companies){
                query.containedIn('relatedCompany', companies);
            }

            query.limit(5000);

            query.find().then(response => {
                // console.log("Response: ", response);
                resolve(response);
            }).catch(err => {
                reject(err);
            });

        })
    },

    getAllInvoices(context, data = {}){
        return new Promise((resolve, reject) => {
            let allMonths = data.months ? data.months : [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ];

            let year = data.year ? data.year : new Date().getFullYear();

            let query = new Parse.Query('Invoice');
            query.include('relatedCompany');
            query.equalTo('year', parseInt(year));
            query.containedIn('month', allMonths);
            query.ascending('month');
            query.limit(5000);

            query.find().then(response => {
                // console.log("Response: ", response);
                resolve(response);
            }).catch(err => {
                reject(err);
            });

        })
    },
    suspendClient(context, data) {
        return new Promise((resolve, reject) => {

            let companiesToSuspend = context.getters.selectedModalData(data.dataSet);
            let savePromises = [];

            companiesToSuspend.forEach(function(element){
                element.set('suspended', true);
                savePromises.push(element.save());
            });

            Promise.all(savePromises).then(response => {
                //console.log("Successfully suspended objects: ", response);
                resolve(response);
            }).catch(err => {
                //console.log("Unable to delete objects: ", err);
                reject(err);
            });

        });
    },
    restoreClient(context, data) {
        return new Promise((resolve, reject) => {

            let dataSet = data.dataSet ? data.dataSet : 'agencyClients';
            let companiesToSuspend = context.getters.selectedModalData(dataSet);
            let savePromises = [];

            companiesToSuspend.forEach(function(element){
                element.set('suspended', false);
                savePromises.push(element.save());
            });

            Promise.all(savePromises).then(response => {
                //console.log("Successfully suspended objects: ", response);
                resolve(response);
            }).catch(err => {
                //console.log("Unable to delete objects: ", err);
                reject(err);
            });

        });
    },
    deleteClient(context) {
        return new Promise((resolve, reject) => {

            let companiesToDelete = context.getters.selectedModalData('agencyClients');
            let compIds = [];

            companiesToDelete.forEach(function(element){
                compIds.push(element.id);
            });

            let cards = Parse.Object.extend('Employee');
            let cardConfig = Parse.Object.extend('CardConfig');
            let addresses = Parse.Object.extend('OfficeAddress');

            let queryCards = new Parse.Query(cards);
            let queryConfig = new Parse.Query(cardConfig);
            let queryAddress = new Parse.Query(addresses);

            queryCards.containedIn('company', compIds);
            queryConfig.containedIn('company', compIds);
            queryAddress.containedIn('company', compIds);

            let promiseCards = queryCards.find();
            let promiseConfig = queryConfig.find();
            let promiseAddress = queryAddress.find();

            let cardsToDelete = [],
                configToDelete = [],
                addressesToDelete = [];

            Promise.all([promiseCards, promiseConfig, promiseAddress]).then(function(values) {
                cardsToDelete = values[0];
                configToDelete = values[1];
                addressesToDelete = values[2];

                let delPromiseCards = Parse.Object.destroyAll(cardsToDelete);
                let delPromiseConfig = Parse.Object.destroyAll(configToDelete);
                let delPromiseAddress = Parse.Object.destroyAll(addressesToDelete);
                let delPromiseCompanies = Parse.Object.destroyAll(companiesToDelete);

                Promise.all([delPromiseCards,delPromiseConfig, delPromiseAddress, delPromiseCompanies]).then(response => {
                    //console.log("Successfully deleted objects: ", response);
                    resolve(response);
                }).catch(err => {
                    //console.log("Unable to delete objects: ", err);
                    reject(err);
                });

            });

        });
    },
    regenerateFrameLink(context){
        let newFrameSecret = generateRandomString(20);
        let client = context.getters.selectedModalData('agencyClients');
        client.set('frameSecret', newFrameSecret);
        return new Promise((resolve, reject) => {
            client.save().then(resp => {
                context.dispatch('getAgencyClients');
                resolve(resp);
            }).catch(err => {
                reject(err);
            })
        });
    },
    goToClientCards(context, data) {
        let formData = new FormData();
        formData.append('action', 'sendCardLink');
        formData.append('sessionToken', context.state.user.attributes.sessionToken);
        formData.append('data', JSON.stringify({
            sender_id: context.state.user.id,
            employee_ids: data
        }));
        
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
};
