<template>
    <div>
        <form @submit.prevent="submitForm" id="agency-settings-form">
            <h3 class="text-center">
                {{$ml.get('ADMIN_SETTINGS')}}
            </h3>
            <div class="row">
                <div class="col-3"></div>
                <div class="col-6 text-center">
                    <div class="client-avatar lg-avatar">
                        <img v-if="compLogo" :src="compLogo" class="company-logo"/>
                    </div>
                    <h4 class="mb-0 mt-1">{{compName}}</h4>
                </div>
            </div>

            <!-- CLIENT OF -->
            <div class="row mb-2">
                <div class="col">
                    <div id="font-selector">
                        <label class="form-label select-2-label">{{$ml.get('CLIENT_OF')}}</label>
                        <Select2
                            v-model="clientOf.id"
                            :options="companies"
                            :placeholder="$ml.get('COMPANY')"
                            :settings="{
                            templateResult: companyPreview,
                            templateSelection: selectionPreview,
                            escapeMarkup: function(stuff){return stuff}
                        }"
                        />
                    </div>

                </div>
            </div>


            <div class="row">
                <div class="col-6">
                    <div class="row pt-2" v-if="isAdmin">
                        <div class="col-12 mb-2">
                            <checkbox-field color="secondary"
                                            :value="form.canUpgrade"
                                            v-model="form.canUpgrade"
                                            :disabled="form.isAgency"
                            >{{$ml.get('this_client_can_upgrade')}}
                            </checkbox-field>
                        </div>
                    </div>
                </div>


                <div class="col-6">
                    <div class="row pt-2" v-if="isAdmin">
                        <div class="col-12 mb-2">
                            <checkbox-field color="secondary"
                                            :value="isAgency"
                                            v-model="isAgency"
                            >{{$ml.get('this_client_is_an_agency')}}
                            </checkbox-field>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="isAdmin">
                <div class="col-6">
                    <div class="row pt-3">
                        <div class="col-12 mb-2">
                            <checkbox-field color="secondary"
                                            :value="form.hasTrial"
                                            v-model="form.hasTrial"
                            >{{$ml.get('this_is_trial_account')}}
                            </checkbox-field>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row pt-1" v-if="form.hasTrial">
                        <div class="col-12 mb-2">
                            <date-field :id="`issue-date`"
                                        :name="'issue-date'"
                                        type="date"
                                        :value="form.trialUntil"
                                        v-model="form.trialUntil">{{$ml.get('trial_until')}}
                            </date-field>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-6" v-if="form.isAgency">
                    <div class="dz-profile-pic">
                        <span class="more-info" v-tooltip="$ml.get('tooltip_agency_panel_logo')">
                            <font-awesome-icon icon="info-circle"/>
                        </span>
                        <dropzone-field name="profilePicture"
                                        id="profilePicture"
                                        :accept="`image/*`"
                                        :required="true"
                                        @validation-result="validationCollector"
                                        v-model="fileTrigger">
                            <img :src="images.logo" v-if="images.logo"/>
                            <template v-else>
                                <div v-html="$ml.get('AGENCY_LOGO_DROPZONE')"></div>
                            </template>
                        </dropzone-field>
                    </div>
                </div>

                <div class="col-6" v-if="form.isAgency">
                    <div class="row pt-2" v-if="isAdmin">
                        <div class="col-12 mb-2">
                            <checkbox-field color="secondary"
                                            :value="form.verified"
                                            v-model="form.verified"
                            >{{$ml.get('Verified')}}
                            </checkbox-field>
                            <div v-if="form.verified && form.verifiedOn" class="trial-subscript text-secondary">
                                <span>{{$ml.get(`Verified_on`)}}: {{form.verifiedOn | dateFormat}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2" v-if="isAdmin">
                        <div class="col-12 mb-2">
                            <checkbox-field color="secondary"
                                            :value="form.lightLogoBg"
                                            v-model="form.lightLogoBg"
                            >{{$ml.get('agency_use_light_logo_bg')}}
                            </checkbox-field>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <hr/>
                </div>

            </div>


            <!-- SAVE -->
            <div class="row pt-2 justify-content-center">
                <div class="col-6">
                    <button-field color="secondary"
                                  :icon="'save'"
                                  type="submit"
                                  block>{{$ml.get('SAVE')}}
                    </button-field>
                </div>
            </div>

            <!-- REGENERATE SELF-ONBOARDING LINK -->
            <div v-if="false" class="row pt-4 pb-5 justify-content-center">
                <div class="col-12">
                    <h4 class="mb-2 mt-3">{{$ml.get('SELF_ONBOARDING')}}</h4>
                </div>
                <div class="col-12">
                    <input-field :id="`office-state`"
                                 :name="'office-state'"
                                 :disabled="true"
                                 :value="onboardingLink"
                                 v-model="onboardingLink">iFrame URL for self-onboarding of employees
                    </input-field>
                </div>
                <div class="col-6">
                    <button-field color="primary"
                                  :icon="`link`"
                                  :disabled="resetLoading"
                                  @click="copyFrameOnboardLink"
                                  block>{{$ml.get('COPY_LINK')}}
                    </button-field>
                </div>
                <div class="col-6">
                    <button-field color="dark"
                                  :icon="`redo`"
                                  :loading="resetLoading"
                                  :disabled="resetLoading"
                                  @click="resetLink"
                                  block>{{$ml.get('RESET_ONBOARDING_LINK')}}
                    </button-field>
                </div>
            </div>

        </form>

        <!-- CALENDLY STUFF -->
        <div v-if="id && isAdmin" class="row pt-4 justify-content-center">
            <div class="col-12">
                <h4 class="mb-2 mt-3">{{$ml.get('SEND_CALENDLY_LINK')}}</h4>
                <hr/>
            </div>
            <div class="col-6">
                <input-field :id="`calendlyEmail`"
                             :name="'calendlyEmail'"
                             :value="calendlyEmail"
                             :required="true"
                             :ext-validator="emailValidator"
                             @validation-result="validationCalendly"
                             v-model="calendlyEmail">{{$ml.get('CALENDLY_EMAIL')}}
                </input-field>
            </div>
            <div class="col-6">
                <button-field color="primary"
                              :icon="`paper-plane`"
                              :disabled="isCalendlyDisabled || loadingEmail"
                              :loading="loadingEmail"
                              @click="sendCalendlyEmail"
                              block>{{$ml.get('SEND_CALENDLY_EMAIL')}}
                </button-field>
            </div>
            <div v-if="calendlySentOn" class="col-12 text-center">
                <span  class="small warn">A calendly meeting e-mail had already been sent on {{niceDate(calendlySentOn)}}!</span>
            </div>

        </div>

        <div class="row pt-4 justify-content-center">
            <div class="col-12">
                <h4 class="mb-2 mt-3">{{$ml.get('PRICING')}}</h4>
                <hr/>
            </div>
            <div class="col-12">
                <div class="row" v-for="tier in tiersLvls" :key="`pricing-tier-input-${tier}`">
                    <div class="col-6 pt-2">
                        <small v-if="pricingTiersData[`threshold${tier}`] < 10001">
                            From {{ pricingTiersData[`threshold${tier-1}`] ? pricingTiersData[`threshold${tier-1}`] : 1 | thousands }} to {{ pricingTiersData[`threshold${tier}`]-1  | thousands }} cards
                        </small>
                        <small v-else>
                            More than {{ pricingTiersData[`threshold${tier-1}`] | thousands }} cards
                        </small>

                    </div>
                    <div class="col-6">

                        <input-field
                            :id="`pricing-tier-${tier}`"
                            :name="`pricing-tier-${tier}`"
                            :type="`number`"
                            :step="0.01"
                            :value="parseFloat(pricingTiersData[`pricingTier${tier}`], 10).toFixed(2)"
                            v-model="pricingTiersData[`pricingTier${tier}`]"
                        >
                            {{$ml.get(`Price_per_card_for_tier`)+` ${tier}`}}
                        </input-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-2 justify-content-between">
            <div class="col-6">
                <button-field color="secondary"
                              :icon="'save'"
                              @click="savePrices"
                              block>{{$ml.get('SAVE_PRICES')}}
                </button-field>
            </div>
            <div class="col-6">
                <button-field color="dark"
                              :icon="'history'"
                              @click="setDefaultPrices"
                              block>{{$ml.get('RESET_PRICES')}}
                </button-field>
            </div>
        </div>

    </div>
</template>

<script>
    import moment from "moment";
    import Select2 from "v-select2-component";

    export default {
        name: "DashboardAgencySettingsForm",
        components: {
            Select2
        },
        data() {
            let dataSet = this.$store.state.dashboardModalComponent.data.dataSet ? this.$store.state.dashboardModalComponent.data.dataSet : 'masterAgencies';

            let initialAttr = this.$store.getters.selectedModalData(dataSet);

            return {
                fileTrigger: {},
                images: {
                    logo: initialAttr && initialAttr.attributes.agencyPanelLogo ? initialAttr.attributes.agencyPanelLogo._url : ''
                },
                imgFiles: {
                    logo: null
                },
                clientOf: initialAttr ? initialAttr.attributes.clientOf : {},
                compName: initialAttr ? initialAttr.attributes.name : '',
                compLogo: initialAttr && initialAttr.attributes.logo ? initialAttr.attributes.logo._url : '',
                isAgency: initialAttr ? initialAttr.attributes.isAgency: false,

                form: {
                    logo: initialAttr && initialAttr.attributes.agencyPanelLogo ? initialAttr.attributes.agencyPanelLogo : {},
                    clientOf: initialAttr ? initialAttr.attributes.clientOf : {},
                    name: initialAttr ? initialAttr.attributes.name : '',
                    isAgency: initialAttr ? initialAttr.attributes.isAgency: false,
                    lightLogoBg: initialAttr ? initialAttr.attributes.lightLogoBg: false,
                    hasTrial: initialAttr.attributes.trialUntil ? true : false,
                    canUpgrade: initialAttr ? initialAttr.attributes.canUpgrade : false,
                    verified: initialAttr ? initialAttr.attributes.verified : false,
                    verifiedOn: initialAttr && initialAttr.attributes.verifiedOn ? initialAttr.attributes.verifiedOn : null,
                    trialUntil: initialAttr ? initialAttr.attributes.trialUntil : null,
                },
                calendlySentOn: initialAttr && initialAttr.attributes.calendlySentOn ? initialAttr.attributes.calendlySentOn : null,
                calendlyEmail: initialAttr ? initialAttr.attributes.billingEmail : '',
                loading: false,
                resetLoading: false,
                loadingEmail: false,
                errors: [],
                calendlyErrors: [],
                infoBox1Visible: false,
                defaultAgency: {},
                pricingTiersData: {
                    pricingTier1: 3.0,
                    pricingTier2: 2.8,
                    pricingTier3: 2.6,
                    pricingTier4: 2.4,
                    pricingTier5: 2.2,
                    threshold1: 100,
                    threshold2: 500,
                    threshold3: 2000,
                    threshold4: 10000,
                    threshold5: 1000000
                },
                defaultAgencyTiers: {
                    pricingTier1: 2.40,
                    pricingTier2: 2.24,
                    pricingTier3: 2.08,
                    pricingTier4: 1.92,
                    pricingTier5: 1.76,
                    threshold1: 100,
                    threshold2: 500,
                    threshold3: 2000,
                    threshold4: 10000,
                    threshold5: 1000000
                },
                defaultClientTiers: {
                    pricingTier1: 2.00,
                    pricingTier2: 1.90,
                    pricingTier3: 1.80,
                    pricingTier4: 1.70,
                    pricingTier5: 1.50,
                    threshold1: 100,
                    threshold2: 500,
                    threshold3: 2000,
                    threshold4: 10000,
                    threshold5: 1000000
                },
                pricingObj: {},
                tiersLvls:[1, 2, 3, 4, 5]
            }
        },
        computed: {
            pricingTiers() {
                // The computed property now simply returns the data property
                return this.pricingTiersData;
            },
            source(){
                let theSource = this.$store.state.dashboardModalComponent.data.dataSet ? this.$store.state.dashboardModalComponent.data.dataSet : 'agencyClients';
                return theSource;
            },
            emailValidator(){
                return function(value) {
                    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(String(value).toLowerCase());
                }
            },
            isAdmin() {
              return this.$store.state.user.attributes.isAdmin;
            },

            id() {
                let theData = this.$store.state.dashboardModalComponent.data.id;
                console.log("ID", theData);
                //return this.$store.state.dashboardModalComponent.data.id
                return theData;
            },
            companies() {
                let self = this;
                let companies = [{
                    id: 0,
                    text: "None",
                    fullData: {
                        name: "None",
                        logo: {
                            _url: require('../../assets/noun-empty.svg')
                        }
                    }
                }];
                if(this.$store.state.masterAgencies.length > 0){
                    let clientList = [...this.$store.state.masterAgencies];
                    clientList.push(this.$store.state.userCompany);
                    this.$store.state.masterAgencies.forEach((comp) => {
                        if(comp.attributes.isAgency){

                            if(comp.attributes.name == 'qfecard Master') {
                                self.defaultAgency = comp;
                            }

                            let element = {
                                id: comp.id,
                                text: comp.attributes.name,
                                fullData: comp.attributes
                            };
                            companies.push(element);
                        }
                    })
                    if(this.$store.state.userCompany.attributes.core){
                        let element = {
                            id: this.$store.state.userCompany.id,
                            text: this.$store.state.userCompany.attributes.name,
                            fullData: this.$store.state.userCompany.attributes
                        };
                        companies.push(element);
                    }
                }
                // console.log("SELECT COMPANIES", companies);
                // console.log("clientOf", this.clientOf);
                return companies;
            },
            selectedClient() {
                return this.$store.getters.selectedModalData(this.source);
            },
            onboardingLink(){
                let invitationLink = '';
                let client = this.selectedClient;
                if(this.id && client){
                    invitationLink = window.location.protocol + '//' + window.location.host
                        +'/addme/'+this.id+'/'+client.attributes.frameSecret;
                }
                return invitationLink
            },
            isFormDisabled(){
                return this.errors.length > 0;
            },
            isCalendlyDisabled(){
                return this.calendlyErrors.length > 0;
            }

        },
        created(){
            this.$store.dispatch('getMasterAgencies');
            this.fetchPricingTiers();
        },
        methods: {
            setDefaultPrices(){
                if(this.form.isAgency){
                    this.pricingTiersData = {...this.defaultAgencyTiers};
                }else {
                    this.pricingTiersData = {...this.defaultClientTiers};
                }
            },
            savePrices(){
                this.$store.dispatch('savePricingTiers', {prices: this.pricingObj, newPrices: this.pricingTiersData}).then(() => {
                    this.$notify({
                        group: 'notification',
                        type: 'success',
                        text: this.$ml.get('notification_success_prices'),
                    });
                    this.$store.commit('toggleDashboardModal');
                });
            },
            fetchPricingTiers() {
                if (this.id) {
                    this.$store.dispatch('getCompanyPricing', this.id).then((resp) => {
                        this.pricingObj = resp; // Update id property
                        this.pricingTiersData = {...resp.attributes}; // Update data property
                        console.log("Pricing tiers here", this.pricingTiersData);
                    });
                }
            },


            companyPreview(choice){
                let markup = '';
                if(choice.fullData){
                    let item = choice.fullData;

                    if(item.suspended){
                        markup = `<div class="company suspended">`;
                    }else {
                        markup = `<div class="company">`;
                    }
                    markup += `<span class="profile-avatar">`;
                    if(item.logo){
                        markup += `<img class="comp-logo" src="`+item.logo._url+`" />`;
                    }else {
                        markup += `<img class="comp-logo" src="`+require('../../assets/noun-empty.svg')+`" />`;
                    }

                    markup += `</span>`;
                    markup += `<h3>`+item.name+`</h3>`;
                    markup +=`</div>`;
                }
                return markup;
            },
            selectionPreview(selection){

                let markup = '';
                if(selection.fullData){
                    let item = selection.fullData;
                    markup = `<div class="company selection">`;
                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="`+item.logo._url+`" />`;
                    markup += `</span>`;
                    markup += `<h3>`+item.name+`</h3>`;
                    markup +=`</div>`;
                }
                return markup;
            },
            niceDate(date){
                return moment(date).format('DD/MM/Y');
            },
            resetLink(){
                this.resetLoading = true;
                this.$store.dispatch('regenerateFrameLink', this.id).then(()=>{
                    this.resetLoading = false;
                });
            },
            copyFrameOnboardLink(){

                navigator.clipboard.writeText(this.onboardingLink);

                this.$notify({
                    group: 'notification',
                    type: 'success',
                    text: this.$ml.get('notification_onboard_link_copied')
                });

            },
            showMoreInfo(){
                this.infoBox1Visible=true;
            },
            sendCalendlyEmail(){
                let self = this;
                self.loadingEmail = true;
                this.$store.dispatch('sendCalendlyEmail', {
                    id: this.id,
                    calendlyEmail: this.calendlyEmail}).then((resp) => {
                    self.calendlySentOn = this.niceDate(resp.data.calendlySentOn.date);
                    self.$notify({
                        group: 'notification',
                        type: 'success',
                        text: self.$ml.get('notification_success_calendly'),
                    });
                    self.loadingEmail = false;
                    self.$store.commit('toggleDashboardModal');
                }).catch(() => {
                    self.$notify({
                        group: 'notification',
                        type: 'error',
                        text: self.$ml.get('notification_error_calendly'),
                    });
                    self.loadingEmail = false;
                });
            },
            validationCollector(inputValidation){
                Array.prototype.remove = function() {
                    var what, a = arguments, L = a.length, ax;
                    while (L && this.length) {
                        what = a[--L];
                        while ((ax = this.indexOf(what)) !== -1) {
                            this.splice(ax, 1);
                        }
                    }
                    return this;
                };

                if(inputValidation.valid){
                    this.errors.remove(inputValidation.name);
                }else {
                    if(this.errors.indexOf(inputValidation.name) === -1){
                        this.errors.push(inputValidation.name);
                    }
                }
            },

            validationCalendly(inputValidation){
                Array.prototype.remove = function() {
                    var what, a = arguments, L = a.length, ax;
                    while (L && this.length) {
                        what = a[--L];
                        while ((ax = this.indexOf(what)) !== -1) {
                            this.splice(ax, 1);
                        }
                    }
                    return this;
                };

                if(inputValidation.valid){
                    this.calendlyErrors.remove(inputValidation.name);
                }else {
                    if(this.calendlyErrors.indexOf(inputValidation.name) === -1){
                        this.calendlyErrors.push(inputValidation.name);
                    }
                }
            },

            submitForm() {
                let self = this;
                //this.validateFields(this.form, this.errors);
                if (!self.loading) {
                    self.loading = true;

                    self.$store.dispatch('saveAdminClient', {id: self.id, ...self.form, dataSet: this.source}).then(() => {

                        self.$notify({
                            group: 'notification',
                            type: 'success',
                            text: self.$ml.get('notification_success_saved'),
                        });
                        self.$store.commit('toggleDashboardModal');

                    });
                }
            }
        },
        watch: {
            isAgency: {
                handler(newVal) {
                    this.form.isAgency = newVal;
                    if(newVal){
                        this.clientOf = this.$store.state.userCompany;
                        this.form.clientOf = this.$store.state.userCompany;
                        this.form.canUpgrade = true;
                    }else {
                        this.form.canUpgrade = true

                        this.form.verified = false;
                        this.form.verifiedOn = null;
                        this.clientOf = this.defaultAgency;
                        this.form.clientOf = this.defaultAgency;
                    }
                },
                deep: true,
            },
            clientOf: {
                handler(newVal) {
                    this.form.clientOf = newVal;
                },
                deep: true,
            },
            id: {
                handler(newVal) {
                    if(newVal){
                        this.fetchPricingTiers();
                    }
                },
                immediate: true,
                deep: true
            },
            fileTrigger: {
                handler(newVal) {
                    if(newVal){
                        this.imgFiles[newVal.field] = newVal.file;
                        this.images[newVal.field] = newVal.url;
                        this.form.logo = newVal;
                    }
                },
                deep: true,
            },
        }
    }
</script>

<style scoped>
    .select2-container{
        width: 100% !important;
        padding: 20px 0;
    }
    .selection {
        padding-top: 5px;
    }
    .select2-container--default .select2-selection--single{
        min-height: 50px;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
        top: 36px;
    }
    .more-info {
        position: absolute;
        right: 18px;
        top: 3px;
        z-index: 100;
    }
    .form-label {
        position: relative;
        width: 100%;
        color: var(--color-dark);
        /* transition: all 0.25s ease-in-out; */
        text-transform: uppercase;
        font-size: 10px;
        cursor: text;
        pointer-events: none;
    }
</style>
