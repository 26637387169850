<template>
    <div class="qf-wizzard-container">

        <div class="qf-wizzard h100">
            <div class="wizard-header-container" :class="headerPadding">
                <button-field
                            v-if="isAgency"
                                icon="arrow-left"
                              color="primary"
                              class="wizzard-back-button"
                              v-tooltip="$ml.get('tooltip_onboard_link')"
                              @click="closeWizzard()"
                >{{$ml.get('BACK')}}</button-field>
                <div class="wizard-logo">
                    <img :src="logoModeUrl" alt="logo" class="wizard-logo">
                </div>
            </div>
            <transition name="fade" v-if="!isAgency">
                <div class="promo-stripe" v-if="promo.code && trialNoticeOpen">
                    <span class="notice-text">You are using promo code <strong>{{ promo.code }}: </strong> {{ removeBr(promo.description) }}</span>
                    <div class="notice-close_control"><font-awesome-icon icon="times" @click="closeTrialNotice"></font-awesome-icon></div>
                </div>
            </transition>
            <div v-if="!isAgency && !isDirect"  :class="isTabActive(maximumSteps+1) ? 'vis-hidden' : ''">
                <div class="text-center col-12">
                    <p>Signup for a 14-day trial of qfecard by filling out your company data below.</p>
                </div>
                <hr>
            </div>

            <div class="qf-wizzard-flex" :class="isDirect ? 'pt-5': ''">

                <div class="wizzard-tab-phone">
                    <div id="qf-wizzard-frame" class="soft-transition" :class="isTabActive(maximumSteps+1) ? 'vis-hidden' : ''">
                        <div class="wrapper-phone iphone sc06">
                            <iframe class="cardPreview"
                                    name="cardPreview"
                                    :src="`${apiUrl}cards/render/?pr=1&t=XX_ecobcard&d=1`"  frameborder="0"></iframe>
                        </div>
                    </div>
                </div>

                <div class="wizzard-tab-content">

                    <!-- DESIGN PARAMETERS -->
                    <div v-if="isStepActive(1)"  class="step-container h100"
                         v-bind:key="`tabStep2`"
                         :class="isStepActive(1)">


                            <input type="hidden" name="preview_data" value="1" />

                            <div class="row h100">
                                <div class="col-12 col-sm-8 flex-v-center">

                                    <!-- 1. APP ICON & LOGO -->
                                    <div class="step-container"
                                         v-bind:key="`tabStep1`"
                                         :class="isTabActive(1)">

                                        <div v-if="isAgency" class="row">
                                            <div class="col-12">
                                                <h3 class="mb-3 border-bottom">Client logo</h3>
                                                <p class="text-muted mb-2">To setup the client account a logo is required. I will be initially set for the card design and the company profile.</p>
                                                <p class="text-muted mb-5">It is recommended to upload the logo in .SVG or .PNG format.</p>
                                            </div>
                                        </div>


                                        <div v-if="!isAgency" class="row">
                                            <div class="col-12">
                                                <h3 class="mb-3 border-bottom">Corporate identity</h3>
                                                <p class="text-muted mb-2">Your company logo and existing business card design will be used for reference by our design team to create your all-new digital business card.</p>
                                                <p class="text-muted mb-5">If possible please provide your logo in .SVG or .PNG format.</p>
                                            </div>
                                        </div>

                                        <div class="row">

                                            <div class="col-12">

                                                <div>
                                                    <span class="more-info" v-tooltip="$ml.get('tooltip_comp_logo')">
                                                        <font-awesome-icon icon="info-circle"/>
                                                    </span>

                                                    <vue-dropzone ref="imgLogo"
                                                                  :duplicateCheck="true"
                                                                  @vdropzone-queue-complete="queComplete"
                                                                  @vdropzone-error="dropzoneError('imgLogo', ...arguments)"
                                                                  @vdropzone-file-added="onFileAdded('imgLogo', ...arguments)"
                                                                  @vdropzone-removed-file="removedFile('imgLogo', ...arguments)"
                                                                  id="dz-imgLogo"
                                                                  class="eco-dropzone mb-3"
                                                                  :options="dropzoneLogoOptions"></vue-dropzone>

<!--                                                    <dropzone-field-->
<!--                                                                    v-if="false"-->
<!--                                                                    :name="`imgLogo`"-->
<!--                                                                    :id="`imgLogo`"-->
<!--                                                                    :accept="`image/*`"-->
<!--                                                                    :required="true"-->
<!--                                                                    :max-file-size="5"-->
<!--                                                                    @validation-result="validationCollector"-->
<!--                                                                    v-model="fileTrigger">-->
<!--                                                        <template v-if="images.imgLogo && images.imgLogo._url">-->
<!--                                                            <img :src="images.imgLogo._url"-->
<!--                                                                 v-if="images.imgLogo._url !== undefined">-->
<!--                                                        </template>-->
<!--                                                        <template v-else>-->
<!--                                                            <div v-html="$ml.get('COMPANY_LOGO')"></div>-->
<!--                                                        </template>-->
<!--                                                    </dropzone-field>-->
                                                </div>

                                            </div>


                                            <div class="col-12" v-if="!noPrintCard">
                                                <div class="row mb-3">
                                                    <div class="col-6">
                                                        <span class="more-info" v-tooltip="$ml.get('tooltip_card_scan')">
                                                            <font-awesome-icon icon="info-circle"/>
                                                        </span>
                                                        <vue-dropzone ref="imgCardScanFront"
                                                                      :duplicateCheck="true"
                                                                      @vdropzone-queue-complete="queComplete"
                                                                      @vdropzone-error="dropzoneError('imgCardScanFront', ...arguments)"
                                                                      @vdropzone-file-added="onFileAdded('imgCardScanFront', ...arguments)"
                                                                      @vdropzone-removed-file="removedFile('imgCardScanFront', ...arguments)"
                                                                      id="dz-imgCardScanFront"
                                                                      class="eco-dropzone"
                                                                      :options="dropzoneCardScanFrontOptions"></vue-dropzone>

<!--                                                        <dropzone-field name="imgCardScanFront"-->
<!--                                                                        id="imgCardScanFront"-->
<!--                                                                        :accept="`image/*`"-->
<!--                                                                        :required="true"-->
<!--                                                                        :max-file-size="5"-->
<!--                                                                        @validation-result="validationCollector"-->
<!--                                                                        v-model="fileTrigger">-->
<!--                                                            <img :src="images.imgCardScanFront" v-if="images.imgCardScanFront"/>-->
<!--                                                            <template v-else>-->
<!--                                                                <div v-html="$ml.get('clientCardScanDropzoneFront')"></div>-->
<!--                                                            </template>-->
<!--                                                        </dropzone-field>-->
<!--                                                        <hr/>-->

                                                    </div>
                                                    <div class="col-6">
                                                        <span class="more-info" v-tooltip="$ml.get('tooltip_card_scan')">
                                                            <font-awesome-icon icon="info-circle"/>
                                                        </span>

                                                        <vue-dropzone ref="imgCardScanBack"
                                                                      :duplicateCheck="true"
                                                                      @vdropzone-queue-complete="queComplete"
                                                                      @vdropzone-error="dropzoneError('imgCardScanBack', ...arguments)"
                                                                      @vdropzone-file-added="onFileAdded('imgCardScanBack', ...arguments)"
                                                                      @vdropzone-removed-file="removedFile('imgCardScanBack', ...arguments)"
                                                                      id="dz-imgCardScanBack"
                                                                      class="eco-dropzone"
                                                                      :options="dropzoneCardScanBackOptions"></vue-dropzone>
<!--                                                        <dropzone-field name="imgCardScanBack"-->
<!--                                                                        id="imgCardScanBack"-->
<!--                                                                        :accept="`image/*`"-->
<!--                                                                        :max-file-size="5"-->
<!--                                                                        v-model="fileTrigger">-->
<!--                                                            <img :src="images.imgCardScanBack" v-if="images.imgCardScanBack"/>-->
<!--                                                            <template v-else>-->
<!--                                                                <div v-html="$ml.get('clientCardScanDropzoneBack')"></div>-->
<!--                                                            </template>-->
<!--                                                        </dropzone-field>-->
<!--                                                        <hr/>-->

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 mb-2" v-if="!isAgency">
                                                <checkbox-field color="secondary"
                                                                name="confirm"
                                                                id="no_card"
                                                                :value="noPrintCard"
                                                                v-model="noPrintCard">
                                                    {{$ml.get('no_print_card_checkbox')}}
                                                </checkbox-field>
                                            </div>


                                        </div>

                                    </div>

                                    <!-- 2. COMPANY INFO -->
                                    <div class="step-container"
                                         v-bind:key="`sideTabStep2`"
                                         :class="isTabActive(2)">


                                        <div class="row" v-if="isAgency">
                                            <div class="col-12">
                                                <h3 class="mb-3 border-bottom">Company information</h3>
                                                <p class="text-muted mb-1">The information below is required for legal and billing purposes. If you are unsure of all the details you can also edit them later.</p>
                                                <p class="text-muted mb-5">The actual contact details that their partners will see when they scan their card are filled-out on the next step.</p>
                                            </div>
                                        </div>

                                        <div class="row" v-if="!isAgency">
                                            <div class="col-12">
                                                <h3 class="mb-3 border-bottom">Company information</h3>
                                                <p class="text-muted mb-1">The information below is required in order to be used to setup your account with qfecard.</p>
                                                <p class="text-muted mb-5">The actual contact details that your partners will see when they scan your card are filled-out on the next step.</p>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-6 mb-3">
                                                <input-field :id="`name`"
                                                             :name="'name'"
                                                             :value="companyForm.name"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.name">{{ $ml.get('COMPANY_NAME') }}
                                                </input-field>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <input-field :id="`vatNumber`"
                                                             :name="'vatNumber'"
                                                             :value="companyForm.vatNumber"
                                                             :required="true"
                                                             v-model="companyForm.vatNumber">{{ $ml.get('VAT_REG_NUMBER') }}
                                                </input-field>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <input-field :id="`billingAddressStreet`"
                                                             :name="'billingAddressStreet'"
                                                             :value="companyForm.billingAddressStreet"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.billingAddressStreet">{{ $ml.get('BILLING_ADDRESS_STREET') }}
                                                </input-field>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <input-field :id="`billingAddressCity`"
                                                             :name="'billingAddressCity'"
                                                             :value="companyForm.billingAddressCity"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.billingAddressCity">{{ $ml.get('BILLING_ADDRESS_CITY') }}
                                                </input-field>
                                            </div>

                                            <div class="col-4 mb-3">
                                                <input-field :id="`billingAddressZip`"
                                                             :name="'billingAddressZip'"
                                                             :value="companyForm.billingAddressZip"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.billingAddressZip">{{ $ml.get('BILLING_ADDRESS_ZIP_CODE') }}
                                                </input-field>
                                            </div>
                                            <div class="col-4 mb-3">
                                                <input-field :id="`billingAddressState`"
                                                             :name="'billingAddressState'"
                                                             :value="companyForm.billingAddressState"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.billingAddressState">{{ $ml.get('STATE') }}
                                                </input-field>
                                            </div>
                                            <div class="col-4 mb-3">
                                                <input-field :id="`billingAddressCountry`"
                                                             :name="'billingAddressCountry'"
                                                             :value="companyForm.billingAddressCountry"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.billingAddressCountry">{{ $ml.get('COUNTRY') }}
                                                </input-field>
                                            </div>


                                            <div class="col-6 mb-3">
                                                <input-field :id="`responsible`"
                                                             :name="'responsible'"
                                                             :value="companyForm.responsible"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.responsible">{{ $ml.get('RESPONSIBLE') }}
                                                </input-field>
                                            </div>

                                            <div class="col-6 mb-3">
                                                <input-field :id="`contactPerson`"
                                                             :name="'contactPerson'"
                                                             :value="companyForm.contactPerson"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.contactPerson">{{ $ml.get('CONTACT_PERSON') }}
                                                </input-field>
                                            </div>

                                            <div class="col-6 mb-3">
                                                <input-field :id="`billingEmail`"
                                                             :name="'billingEmail'"
                                                             :value="companyForm.billingEmail"
                                                             :required="true"
                                                             :ext-validator="emailValidator"
                                                             @validation-result="validationCollector"
                                                             v-model="companyForm.billingEmail">{{ $ml.get('EMAIL') }}
                                                </input-field>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <input-field :id="`telephone`"
                                                             :name="`telephone`"
                                                             :value="companyForm.telephone"
                                                             v-model="companyForm.telephone">{{ $ml.get('TELEPHONE') }}
                                                </input-field>
                                            </div>
                                            <div class="col-12 mb-3">
                                                <input-field :id="`website`"
                                                             :name="'website'"
                                                             :value="companyForm.website"
                                                             v-model="companyForm.website">{{ $ml.get('WEBSITE') }}
                                                </input-field>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- 3. OFFICE ADDRESS -->
                                    <div class="step-container"
                                         v-bind:key="`sideTabStep3`"
                                         :class="isTabActive(3)">

                                        <div class="row" v-if="isAgency">
                                            <div class="col-12">
                                                <h3 class="mb-3 border-bottom">Office address</h3>
                                                <p class="text-muted mb-1">On this step the contact details that will be viewable on the client card are required.</p>
                                                <p class="text-muted mb-5">You can always add more office addresses so that different employees can be assigned to different locations and departments.</p>
                                            </div>
                                        </div>


                                        <div class="row" v-if="!isAgency">
                                            <div class="col-12">
                                                <h3 class="mb-3 border-bottom">Office address</h3>
                                                <p class="text-muted mb-1">On this step we request the contact details that will be viewable on your card.</p>
                                                <p class="text-muted mb-5">In its full version qfecard supports multiple office addresses so that different employees can be assigned to different locations and departments.</p>
                                            </div>
                                        </div>


                                        <div class="row">

                                            <div class="col-12">
                                                <button-field
                                                    color="secondary"
                                                    icon="copy"
                                                    class="mb-5"
                                                    @click="copyBillingToOfficeForm()"
                                                >
                                                    {{ $ml.get('COPY_FROM_BILLING')}}

                                                </button-field>
                                            </div>

                                            <div class="col-12 mb-3">
                                                <input-field :id="`office-address`"
                                                             :name="'office-address'"
                                                             :value="officeAddressForm.street"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="officeAddressForm.street">{{$ml.get('STREET_ADDRESS')}}
                                                </input-field>
                                            </div>

                                            <div class="col-6 mb-3">
                                                <input-field :id="`office-city`"
                                                             :name="'office-city'"
                                                             :value="officeAddressForm.city"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="officeAddressForm.city">{{$ml.get('CITY')}}
                                                </input-field>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <input-field :id="`office-zip`"
                                                             :name="'office-zip'"
                                                             :value="officeAddressForm.zip"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="officeAddressForm.zip">{{$ml.get('ZIP')}}
                                                </input-field>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <input-field :id="`office-state`"
                                                             :name="'office-state'"
                                                             :value="officeAddressForm.state"
                                                             v-model="officeAddressForm.state">{{$ml.get('STATE')}}
                                                </input-field>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <input-field :id="`office-country`"
                                                             :name="'office-country'"
                                                             :value="officeAddressForm.country"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="officeAddressForm.country">{{$ml.get('COUNTRY')}}
                                                </input-field>
                                            </div>

                                            <div class="col-6 mb-3">
                                                <input-field :id="`office-phone`"
                                                             :name="'office-phone'"
                                                             :value="officeAddressForm.officePhone"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="officeAddressForm.officePhone">{{$ml.get('OFFICE_PHONE')}}
                                                </input-field>
                                            </div>

                                            <div class="col-6 mb-3">
                                                <input-field :id="`office-email`"
                                                             :name="'office-email'"
                                                             :value="officeAddressForm.officeEmail"
                                                             :required="true"
                                                             @validation-result="validationCollector"
                                                             v-model="officeAddressForm.officeEmail">{{$ml.get('OFFICE_EMAIL')}}
                                                </input-field>
                                            </div>


                                        </div>
                                    </div>


                                    <div class="step-container" v-bind:key="`sideTabStep4`"
                                         :class="isTabActive(4)">

                                        <div class="row">
                                            <div class="col-12">
                                                <h3 class="mb-3 border-bottom">Activate subscription</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <p class="small mt-0">To create your account we need your consent to activate the subscription. The pricing is tiered and billed monthly, based on maximum number of active business cards (number of employees) that were active under your account for a given month. Please, consult the subscription terms below for more details.</p>
                                        </div>

                                        <div class="pricing-holder py-1">
                                            <pricing-table :hardCoded="true" class="mb-3"></pricing-table>
                                        </div>

                                        <div>
                                            <div class="my-2 ">
                                                <checkbox-field v-model="confirm" color="secondary">I have read and agree to the <a href="/terms-regular" target="_blank">subscription terms</a></checkbox-field>
                                            </div>

                                            <div class="row justify-content-center mb-3">
                                                <div class="col-12 pt-4">
                                                    <hr/>
                                                    <p class="small mb-0">Need a formal contract? Request it by <a href="#" @click="openSubscribe">following this link</a> and filling out the form.</p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <!-- 4. Success message -->
                                    <div class="step-container text-center"
                                         v-bind:key="`sideTabStepLast`"
                                         :class="isTabActive(maximumSteps+1)">

                                        <h1 v-if="isDirect">Thank you for signing up!</h1>
                                        <h1 v-else>Thank you for signing up for a 14-day free trial with qfecard!</h1>
                                        <p>Expect our team to reach out to you within a few business-days over email to onboard you!</p>
                                        <button-field
                                            color="secondary"
                                            icon="link"
                                            @click="toWebsite()"
                                            >
                                            Learn more on our website
                                        </button-field>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4 soft-transition" v-if="isTabActive(maximumSteps+1)? '' : 'vis-hidden'">
                                    <ul class="v-tabs hPhone">
                                        <li class="v-tab-item"
                                            :class="isTabActive(1)"
                                            @click="setTab(1)"
                                        >1. Current logo and BCard</li>

                                        <li class="v-tab-item"
                                            :class="isTabActive(2)"
                                            @click="setTab(2)"
                                        >2. Company information</li>
                                        <li class="v-tab-item"
                                            :class="isTabActive(3)"
                                            @click="setTab(3)"
                                        >3. Office address</li>
                                        <li class="v-tab-item"
                                            v-if="isDirect"
                                            :class="isTabActive(4)"
                                            @click="setTab(4)"
                                        >4. Activate subscription</li>

                                    </ul>
                                    <div class="wizzard-buttons">
                                        <checkbox-field
                                            v-if="isOnLastStep && !isAgency"
                                            id="privacy"
                                            name="privacy"
                                            color="secondary"
                                            :required="true"
                                            @input="privacyVal"
                                        >
                                            {{$ml.get('privacy_checkbox')}}
                                            <a :href="privacyDocUrl" target="_blank">{{$ml.get('the_privacy_policy')}}</a>
                                        </checkbox-field>
                                        <br>
                                        <checkbox-field
                                                v-if="isOnLastStep && !isAgency"
                                                id="terms"
                                                name="terms"
                                                color="secondary"
                                                :required="true"
                                                @input="termsVal"
                                                >
                                            {{$ml.get('terms_checkbox')}}
                                            <a href="https://www.qfecard.com/terms-and-conditions/" target="_blank">{{$ml.get('terms_and_conditions')}}</a>
                                        </checkbox-field>
                                        <br>
                                        <button-field
                                            v-if="curActiveTab>1"
                                            color="primary"
                                            icon="arrow-left"
                                            @click="backClicked()"
                                            type="submit"
                                            block>
                                            {{$ml.get('Back')}}
                                        </button-field>

                                        <button-field
                                            v-if="curActiveTab<maximumSteps"
                                            color="secondary"
                                            icon="arrow-right"
                                            @click="nextClicked()"
                                            type="submit"
                                            :disabled="isFormDisabled"
                                            :tooltipText="nextRequirements"
                                            block>
                                            {{$ml.get('Next')}}
                                        </button-field>

                                        <button-field
                                            v-if="curActiveTab === maximumSteps"
                                            color="secondary"
                                            icon="save"
                                            @click="createClientApi()"
                                            type="submit"
                                            :disabled="loading || isFormDisabled || !termsAgreed || !privacyAgreed"
                                            :tooltipText="nextRequirements"
                                            :loading="loading"
                                            block>
                                            {{saveButtonText}}
                                        </button-field>
                                    </div>
                                </div>
                            </div>
                    </div>

                </div>
            </div>
        </div>


    </div>
</template>

<script>
import Parse from "parse";
import PricingTable from "@/components/global/PricingTable.vue";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    name: "trialWizard",
    components: {
        PricingTable,
        VueDropzone: vue2Dropzone,
        //Select2,
    },
    data() {

        return {
            mode: process.env.VUE_APP_MODE,

            trialNoticeOpen: false,
            promo: {
              code: ``,
              description: ``,
              validUntil: ``
            },

            isAgency: false,
            isDirect: false,

            confirm: false,

            hasCompany: false,
            isAdmin: false,
            loading: false,
            noPrintCard: false,
            curUser: {},

            curActiveStep: 1,
            curActiveTab: 1,
            btnActiveStep: 1,
            apiUrl: process.env.VUE_APP_BASE_API_URL,
            editedCompany: {id:null},

            fileTrigger: {},
            images: {
                imgLogo: null,
                imgBgTop: null,
                imgBgFull: null,
                imgCardScanFront: null,
                imgCardScanBack: null,
                imgIcon: process.env.VUE_APP_BASE_API_URL+'/img/icon.png'
            },
            imgFiles: {
                imgLogo: null,
                imgBgTop: null,
                imgBgFull: null,
                imgCardScan: null,
                imgCardScanFront: null,
                imgCardScanBack: null,
                imgIcon: null
            },

            form: {
                fontFamily: 'Alef',
                baseFontSize: 16,
                promo: null,
            },
            cardConfig: {
                attributes: {
                    imgIcon: process.env.VUE_APP_BASE_API_URL+'/img/icon.png'
                }
            },

            officeAddressForm: {
                street: '',
                officeLabel: 'Main Office',
                officePhone: '',
                officeEmail: '',
                city: '',
                zip: '',
                state: '',
                country: '',
            },

            companyForm: {
                name:'',
                billingAddress:'',
                billingAddressStreet:'',
                billingAddressCity:'',
                billingAddressZip:'',
                billingAddressState:'',
                billingAddressCountry:'',
                vatNumber:'',
                responsible:'',
                contactPerson:'',
                billingEmail:'',
                telephone:'',
                website: ''
            },

            selectedTemplate:  {},
            cardDesign: {
                selectedTemplateName: 'XX_ecobcard',
            },

            dropzoneLogoOptions: {
                url: process.env.VUE_APP_API_URL+'createClient',
                paramName: 'imgLogo',
                addRemoveLinks: true,
                autoProcessQueue: false,
                clickable: true,
                dictDefaultMessage: this.$ml.get('COMPANY_LOGO'),
                dictRemoveFile: this.$ml.get('REMOVE'),
                //dictRemoveFileConfirmation: this.i18nT(`Are you sure you want to remove this file?`),
                dictFileTooBig: this.$ml.get('DROPZONE_FILE_TOO_BIG'),
                dictInvalidFileType: this.$ml.get('DROPZONE_INVALID_IMAGE_FILE_TYPE'),
                dictCancelUpload: this.$ml.get('CANCEL'),
                dictMaxFilesExceeded:  this.$ml.get('DROPZONE_ONLY_ONE'),
                maxFilesize: 5,
                maxFiles: 1,
                acceptedFiles: "image/*",
            },
            dropzoneCardScanFrontOptions: {
                url: process.env.VUE_APP_API_URL+'createClient',
                paramName: 'imgLogo',
                addRemoveLinks: true,
                autoProcessQueue: false,
                clickable: true,
                dictDefaultMessage: this.$ml.get('clientCardScanDropzoneFront'),
                dictRemoveFile: this.$ml.get('REMOVE'),
                //dictRemoveFileConfirmation: this.i18nT(`Are you sure you want to remove this file?`),
                dictFileTooBig: this.$ml.get('DROPZONE_FILE_TOO_BIG'),
                dictInvalidFileType: this.$ml.get('DROPZONE_INVALID_MIXED_FILE_TYPE'),
                dictCancelUpload: this.$ml.get('CANCEL'),
                dictMaxFilesExceeded:  this.$ml.get('DROPZONE_ONLY_ONE'),
                maxFilesize: 5,
                maxFiles: 1,
                acceptedFiles: "image/*,application/pdf",
            },
            dropzoneCardScanBackOptions: {
                url: process.env.VUE_APP_API_URL+'createClient',
                paramName: 'imgLogo',
                addRemoveLinks: true,
                autoProcessQueue: false,
                clickable: true,
                dictDefaultMessage: this.$ml.get('clientCardScanDropzoneBack'),
                dictRemoveFile: this.$ml.get('REMOVE'),
                //dictRemoveFileConfirmation: this.i18nT(`Are you sure you want to remove this file?`),
                dictFileTooBig: this.$ml.get('DROPZONE_FILE_TOO_BIG'),
                dictInvalidFileType: this.$ml.get('DROPZONE_INVALID_MIXED_FILE_TYPE'),
                dictCancelUpload: this.$ml.get('CANCEL'),
                dictMaxFilesExceeded:  this.$ml.get('DROPZONE_ONLY_ONE'),
                maxFilesize: 5,
                maxFiles: 1,
                acceptedFiles: "image/*,application/pdf",
            },

            errors: [],
            errorCount: 0,
            termsAgreed: false,
            privacyAgreed: false,
            privacyDocUrl: '/docs/qfecard-privacy-policy.pdf',
            validationSets: {
                1 : [
                    'imgLogo',
                    'imgCardScanFront'
                ],
                2 : [
                    'name',
                    'vatNumber',
                    "billingAddressStreet",
                    "billingAddressCity",
                    "billingAddressZip",
                    "billingAddressCountry",
                    "billingEmail"
                ],
                3 : [
                    "office-address",
                    "office-city",
                    "office-country",
                    "office-zip",
                    "office-phone",
                    "office-email",
                    "terms"
                ],
            },
            infoBox1Visible: false,
            iconTooltip: '<div class="custom-stuff">' +
                            '<h4>This will be the icon that shows <br>on the device of each employee.</h4>' +
                            '<p>Use a 512 x 512 PNG image</p>' +
                         '</div>',
            logoTooltip: '<div class="custom-stuff">' +
                            '<h4>The logo that will appear on the <br> business card design.</h4>' +
                            '<p>Use a PNG or an SVG image</p>' +
                         '</div>',
            steps: [
                {
                    label: 'Company information',
                    slot: 'page1',
                },
            ],
        };
    },
    props: {
        agencyId: {
            type: String,
            default: null,
            required: false,
        },
    },
    mounted() {
        this.curUser = Parse.User.current();

        if(this.curUser && this.curUser.attributes){
            this.isAdmin = this.curUser.attributes.isAdmin;
            if(this.curUser.attributes.company){
                this.hasCompany = true;
            }
        }

        if(this.agencyId || this.$route.name == 'client-add'){
            this.isAgency = true;
        }

        this.$store.dispatch('getCompany');
        this.$store.dispatch('getCardTemplates');
        this.$store.dispatch('getInitialTemplate');
        this.$store.dispatch('getFontFamilies');

        let urlParams = new URLSearchParams(window.location.search);
        let promoCode = urlParams.get('promo');
        let directSignup = urlParams.get('direct');

        if(promoCode){

            this.$store.dispatch('checkPromo', {code: promoCode}).then(response => {
                if (response.data && response.data.code) {
                    this.promo = response.data;
                    this.form.promo = promoCode;
                    this.trialNoticeOpen = true;
                }
            });
        }

        if(directSignup){
            this.isDirect = true;
        }

    },
    methods: {
        queComplete(){
            console.log("queComplete");
        },
        dropzoneError(refName, file, message, xhr){
            console.log("dropzoneError", refName, file, message, xhr);
            let self = this;
            self.$notify({
                group: 'notification',
                type: 'error',
                duration: 5000,
                text: message,
            });
            setTimeout(() => {
                self.$refs[refName].removeAllFiles();
            }, 2000);
        },
        onFileAdded(fieldName, file){
            this.imgFiles[fieldName] = file;
            console.log("Files to upload after ADD: ", this.imgFiles);
        },
        removedFile(fieldName){
            this.imgFiles[fieldName] = null;
            console.log("Files to upload after REMOVE: ", this.imgFiles);
        },
        dropzoneSuccess(file, response){
            console.log("dropzoneSuccess", file, response);
        },
        dropzoneAddParams(file, xhr, formData){
            console.log("dropzoneAddParams", file, xhr, formData);
        },

        openSubscribe(){

            let ulr_base = 'https://www.qfecard.com/confirm-registration/';
            let curUser = Parse.User.current();
            let compData = this.$store.state.userCompany ? this.$store.state.userCompany.attributes : {atrributes: {}};

            if(curUser){
                let params = {
                    email: this.officeAddressForm.officeEmail,
                    firstName: '',
                    lastName: '',
                    company: this.companyForm.name,
                    phone: this.companyForm.telephone,
                    address: this.companyForm.billingAddressStreet+', '+this.companyForm.billingAddressCity+', '+this.companyForm.billingAddressCountry,
                    billingEmail: this.companyForm.billingEmail,
                    vat: this.companyForm.vatNumber,
                    responsible: this.companyForm.responsible,
                    website: this.companyForm.website,
                    companyId: this.$store.state.userCompany.id,
                }

                if(compData.usedPromo && compData.usedPromo.get('code')){
                    params.promo = compData.usedPromo.attributes.code;
                }

                let paramsString = JSON.stringify(params);
                let encodedParams = btoa(paramsString);

                let url = ulr_base +'?data='+ encodedParams;

                window.open(url, '_blank');
            }


        },
        removeBr(str){
            return str.replace(/<br\s*[/]?>/gi, "\n");
        },
        closeWizzard(){
            this.$router.push({ name: 'clients' });
        },

        closeTrialNotice(){
            this.trialNoticeOpen = false;
        },

        copyBillingToOfficeForm() {
            this.officeAddressForm.street = this.companyForm.billingAddressStreet;
            this.officeAddressForm.city = this.companyForm.billingAddressCity;
            this.officeAddressForm.zip = this.companyForm.billingAddressZip;
            this.officeAddressForm.state = this.companyForm.billingAddressState;
            this.officeAddressForm.country = this.companyForm.billingAddressCountry;
            this.officeAddressForm.officeEmail = this.companyForm.billingEmail;
            this.officeAddressForm.officePhone = this.companyForm.telephone;

            let itemsToCheck = this.validationSets[3];

            itemsToCheck.forEach((item) => {
                let validationObj = {
                    valid: true,
                    name: item
                }
                this.validationCollector(validationObj);
            });

        },

        toWebsite(){
          window.location.href="https://www.qfecard.com";
        },

        filterFileds(section, fieldName) {
            let allowField = false;

            let bgFields = [
                'colorBgTop',
                'colorBgInfo',
                'colorBgBottom',
                'colorBgGradient1',
                'colorBgGradient2',
            ];
            let fontFields = [
                'colorName',
                'colorTitle',
                'sizeTitle',
                'colorInfo',
                'colorButton',
                'colorButtonText',
                //'Separator',
                'colorReady'
            ];
            let labelFields = [
                'labelPhone1',
                'labelPhone2',
                'labelEmail',
                'labelAddress',
               // 'Separator',
            ];

            switch (section) {
                case 'backgroundColors' :
                    allowField = Boolean( bgFields.indexOf(fieldName) != -1 )
                    break;

                case 'typography' :
                    allowField = Boolean( fontFields.indexOf(fieldName) != -1 )
                    //debugger;
                    break;

                case 'labels' :
                    allowField = Boolean(labelFields.indexOf(fieldName) != -1 )
                    break;
            }

            return allowField;

        },

        createClientApi() {
            let self = this;

            if (!this.loading) {
                self.loading = true;

                self.officeAddressForm.officeLabel = "Main Office";
                self.companyForm.billingAddress = self.computedAddress;

                let payload = {
                    form: self.companyForm,
                    office: self.officeAddressForm,
                    imgFiles: self.imgFiles,
                    template: self.selectedNewTemplate,
                    promo: self.promo,
                    agencyId: self.agencyId ? self.agencyId : null,
                    isAdmin: self.isAdmin
                };

                if(this.isDirect){
                    payload.directSignup = true;
                }

                //console.log("Payload: ", payload);

                self.$store.dispatch('saveClientApi', payload)
                    .then(() => {
                        //console.log("createResp", createResp);

                        self.loading = false;
                        self.$notify({
                            group: 'notification',
                            type: 'success',
                            text: self.$ml.get('notification_success_saved'),
                        });

                        if(self.isAgency){
                            this.$store.dispatch('getClientsOfAgency', { agencyId: self.agencyId });
                            self.$router.push({name: 'clients'});

                        }else {
                            self.curActiveTab = this.maximumSteps+1;
                        }
                }).catch(()=>{
                    self.$notify({
                        group: 'notification',
                        type: 'error',
                        title: self.$ml.get('notification_error_title'),
                        text: self.$ml.get('notification_error_general'),
                    });
                    //self.curActiveTab = 4;
                    self.loading = false;
                    //console.log("Error message: ", e.message);
                });
            }
        },

        refreshPreview(fast) {
            clearTimeout(this.timeout);
            if(fast){
                this.$refs.cardPreview.submit();
            }else{
                this.timeout = setTimeout(() => {
                    if(this.$refs.cardPreview)
                        this.$refs.cardPreview.submit();
                }, 300);
            }
        },
        selectCard(templateName, templateObject){
            this.$store.commit('setActiveNewTemplate', templateObject);
            this.selectedTemplateId = templateObject.id;
            //this.selectedTemplateSlug = templateName;
        },
        closeView(){
            this.$router.push({name: 'clients'});
        },
        setStep(stepTo) {
            let self = this;
            this.curActiveTab = 0;
            setTimeout(function(){
                self.curActiveTab = stepTo;
                self.btnActiveStep = stepTo;
            }, 200);
        },
        setTab() {
            // let self = this;
            // this.curActiveTab = 0;
            // setTimeout(function(){
            //     self.curActiveTab = stepTo;
            //     self.btnActiveTab = stepTo;
            // }, 0);
        },
        isStepActive(stepNum) {
            if (stepNum === this.curActiveStep) {
                return 'active-step';
            } else {
                return '';
            }
        },
        isTabActive(stepNum) {
            if (stepNum === this.curActiveTab) {
                return 'active-tab';
            } else {
                return '';
            }
        },
        showMoreInfo() {
            this.infoBox1Visible = true;
        },
        termsVal(val){
            this.termsAgreed = val
        },
        privacyVal(val){
            this.privacyAgreed = val
        },
        validationCollector(inputValidation) {
            Array.prototype.remove = function () {
                var what, a = arguments, L = a.length, ax;
                while (L && this.length) {
                    what = a[--L];
                    while ((ax = this.indexOf(what)) !== -1) {
                        this.splice(ax, 1);
                    }
                }
                return this;
            };
            //console.log("Validation input: ", inputValidation)

            if (inputValidation.valid) {
                this.errors.remove(inputValidation.name);
            } else {
                if (this.errors.indexOf(inputValidation.name) === -1) {
                    this.errors.push(inputValidation.name);
                }
            }
        },
        nextClicked() {
            this.setStep(this.curActiveTab+1)
        },
        backClicked() {
            this.setStep(this.curActiveTab-1)
        },
        navToStart() {
            if(this.isAdmin) {
                this.$router.push({name: 'adminCards'});
            }else {
                this.$router.push({name: 'home'});
            }
        }
    },
    computed: {
        isOnLastStep(){
            if(this.isDirect){
                return this.curActiveTab === 4;
            }else {
                return this.curActiveTab === 3;
            }
        },
        maximumSteps(){
            if(this.isDirect){
                return 4;
            }else {
                return 3;
            }
        },
        headerPadding(){
            if(this.isAgency){
                return 'pb-5';
            }else if(this.trialNoticeOpen){
                return 'pb-0';
            }else {
                return ''
            }
        },

        saveButtonText() {
            if(this.isAgency){
                return this.$ml.get('ADD_CLIENT')
            }else {
                if(this.isDirect){
                    return this.$ml.get('SIGN_UP');
                }else{
                    return this.$ml.get('SIGN_UP_TRIAL');
                }

            }
        },
        userCompany() {
            return this.$store.getters['getUserCompany'];
        },
        nextRequirements() {
            let self = this;
            if(this.isFormDisabled && this.curActiveStep){
                let msg = this.$ml.get('tooltip_req_step_default');

                switch (this.curActiveTab){

                    case 1:
                        if(self.noPrintCard){
                            msg = self.$ml.get('tooltip_req_step1A');
                        }else {
                            msg = self.$ml.get('tooltip_req_step1B');
                        }
                        break;

                    case 2:
                        msg = self.$ml.get('tooltip_req_step2');
                        break;

                    case 3:
                        msg = self.$ml.get('tooltip_req_step3');
                        break;

                    default:
                        msg = self.$ml.get('tooltip_req_step_default');

                }
                return msg;
            }else {
                return '';
            }
        },

        computedAddress() {
          let finalAddress = this.companyForm.billingAddressStreet + ', ' +
              this.companyForm.billingAddressZip + ', ';
            if(this.companyForm.billingAddressState){
                finalAddress += this.companyForm.billingAddressState + ', ';
            }
            finalAddress += this.companyForm.billingAddressCity + ', ' +
              this.companyForm.billingAddressCountry;

            return finalAddress;
        },
        logoModeUrl(){
            let logoSrc = require('../../assets/qfecard-logo-white.svg');

            // if(this.mode !== 'production'){
            //     logoSrc = require('../../assets/qfecard-logo-white-dev.svg');
            // }
            return logoSrc;
        },

        selectFonts(){
            let selectData = [];
            if(this.allFontFamilies){
                this.allFontFamilies.forEach(function(oneFont){
                    let oneLine = {
                        id: oneFont.id,
                        text:  oneFont.attributes.name,
                        fullData: oneFont.attributes,
                    }
                    selectData.push(oneLine);
                });
            }
            return selectData;
        },

        allFontFamilies() {
            return this.$store.state.fontFamilies;
        },
        fieldSet() { //fields for currently selected template
            return this.selectedNewTemplate.attributes.availableParams;
        },
        selected(){
            return this.$store.state.cardCompany;
        },
        selectedNewTemplate(){
            let template = this.$store.state.selectedNewTemplate.id ? this.$store.state.selectedNewTemplate  : this.$store.state.initialTemplate;
            return template;
        },
        selectedTemplateSlug(){
            let slug = this.$store.state.selectedNewTemplate.attributes.nice_name ? this.$store.state.selectedNewTemplate.attributes.nice_name  : this.$store.state.initialTemplate.attributes.nice_name;
            return slug;
        },
        // cardConfig() {
        //     //console.log(this.$store.state.cardConfig);
        //     //this.localCardConfig = this.$store.state.cardConfig;
        //     return this.$store.state.cardConfig;
        // },
        isFormDisabled() {
            // Get the validation set for the current step
            const currentStepValidationSet = this.validationSets[this.curActiveTab];
            //console.log("Current validation set", currentStepValidationSet);

            if (!currentStepValidationSet) {
                return false;
            }

            // Check if there are any errors for the current step
            const currentStepErrors = this.errors.filter((error) =>
                currentStepValidationSet.includes(error)
            );
            //console.log("All errors: ", this.errors);
            //console.log("Current active tab: ", this.curActiveTab);
            //console.log("Current step "+this.curActiveTab+" errors: ", currentStepErrors);

            return currentStepErrors.length > 0 && this.curActiveTab > 0;
        },
        availableCards() {
            return this.$store.state.cardTemplates;
        },
        emailValidator() {
            return function (value) {
                let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(value).toLowerCase());
            }
        },
        vatValidator() {
            return function (value) {
                let re = /(AT?U[0-9]{8}$|BE?0[0-9]{9}$|BG?[0-9]{9,10}$|HR?[0-9]{11}$|CY?[0-9]{8}[A-Z]$|CZ?[0-9]{8,10}$|DE?[0-9]{9}$|DK?[0-9]{8}$|EE?[0-9]{9}$|EL?[0-9]{9}$|ES[A-Z][0-9]{7}(?:[0-9]|[A-Z])$|FI?[0-9]{8}$|FR?[0-9A-Z]{2}[0-9]{9}$|GB?([0-9]{9}([0-9]{3}?|[A-Z]{2}[0-9]{3}))$|HU?[0-9]{8}$|IE?[0-9]{7}[A-Z]{1,2}$|IE?[0-9][A-Z][0-9]{5}[A-Z]$|IT?[0-9]{11}$|LT?([0-9]{9}|[0-9]{12})$|LU?[0-9]{8}$|LV?[0-9]{11}$|MT?[0-9]{8}$|NL?[0-9]{9}B[0-9]{2}$|PL?[0-9]{10}$|PT?[0-9]{9}$|RO?[0-9]{2,10}$|SE?[0-9]{12}$|SI?[0-9]{8}$|SK?[0-9]{10})$/;
                let result = re.test(String(value));
                return result;
            };
        },
        zipCodeValidator() {
            return function (value) {
                let re = /[\w]*[\w-]*[\w ]*\d{4,}/;
                return re.test(value);
            }
        },
    },
    watch: {

        isAgency(val){
            if(val){
                this.privacyAgreed = true;
                this.termsAgreed = true;
                this.noPrintCard = true;
            }
        },

        noPrintCard: {
            handler(newVal){
                this.images.imgCardScanFront = null;
                this.imgFiles.imgCardScanFront = null;
                if(newVal){
                    this.validationSets[1] = [
                        'imgLogo',
                    ];
                }else{
                    this.validationSets[1] = [
                        'imgLogo',
                        'imgCardScanFront'
                    ];
                }
            }
        },

        errors: {
            handler(newVal){
                this.errorCount = newVal.length;
            }
        },

        fieldSet: {
            deep: true,
            handler(newVal){

                let self = this;
                let selectedTemplate = this.$store.state.selectedNewTemplate;
                let formFields = {};
                if(newVal){
                    newVal.forEach(function(oneField){
                        switch (oneField.field) {
                            case "imgLogo":
                                self.images.imgLogo = self.images.imgLogo ? self.images.imgLogo : self.apiUrl+'img/icon.png';
                                break;

                            case "imgBgTop":
                                self.images.imgBgTop = self.cardConfig.attributes.imgBgTop ? self.cardConfig.attributes.imgBgTop :  {_url: null};
                                break;

                            case "imgBgFull":
                                self.images.imgBgFull = self.cardConfig.attributes.imgBgFull ? self.cardConfig.attributes.imgBgFull : {_url: null};
                                break;

                            case "Separator":
                                break;

                            default:
                                if(self.$store.state.dashboardModalComponent.data && self.$store.state.dashboardModalComponent.data.useDef){
                                    formFields[oneField.field] =  oneField.defaultValue;
                                }else{
                                    formFields[oneField.field] =  self.cardConfig.attributes[oneField.field] ? self.cardConfig.attributes[oneField.field]: oneField.defaultValue;
                                }
                        }
                    });
                }

                this.form = formFields;
                this.form.logo = this.cardCompany ? this.cardCompany.attributes.logo._url : null;
                //this.form.baseFontSize = '16';
                //debugger;

                if(!this.curSettings){
                    this.curSettings = {...formFields};
                    this.initialTemplate = Object.assign({}, selectedTemplate);
                    this.initialTemplate.attributes = selectedTemplate.attributes;
                }

            }
        },

        fileTrigger: {
            handler(newVal) {
                if(newVal){
                    this.imgFiles[newVal.field] = newVal.file;
                    this.images[newVal.field] = newVal.url;
                    this.defaultsClicked = 0;
                    //this.refreshPreview();
                }
            },
            deep: true,
        },
        // imgFiles: {
        //
        // },
    }
}
</script>

<style>
   .wizzard-tab-content .eco-pricing-table{
        max-width: 100%;
    }
</style>
<style scoped>

.border-bottom {
    border-bottom: 1px solid var(--color-grey);
}

.form-button.wizzard-back-button {
    position: absolute;
    top: 25px;
    left: 30px;
    cursor: pointer;
    z-index: 100;
}

.text-muted {
    color: #6c757d!important;
}

.vis-hidden {
    visibility: hidden;
    opacity: 0;
}

.soft-transition  {
    transition: all 0.3s ease-in-out;
}


.wizard-header-container {
    width: 100%;
    text-align: center;
    display: block;
    padding-bottom: 5px;
}

.wizard-logo {
    display: block;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    background: #2b2b30;
    padding-top: 7px;
    padding-bottom: 7px;
}

.wizard-logo img {
    max-width: 150px;
}


.space {
    width: 100%;
    height: 20px;
    display: block;
}

.select2-container{
    width: 100% !important;
}

.step-container {
    display: none;
}

.active-tab,
.active-step {
    display: block;
}

.col-portrait .dropzone-container {
    height: 520px;
}

.close-control {
    position: absolute;
    right: -10px;
    top: -20px;
}

.hPhone {
    height: 536px;
}
.qf-wizzard-controls,.qf-wizzard-tabs {
    margin: 20px auto;
    width: 1030px;
}

.step-templates {
    height: 536px;
    overflow-y: scroll;
    padding-top: 5px;
}

.step-container {
   max-width: 880px;
    margin: 0 auto;
    width: 100%;
}

ul.qf-wizzard-tabs {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
}

.qf-one-tab.active-step {
    border-bottom: 2px solid var(--color-primary);
    color: var(--color-primary);
}

.qf-wizzard-tabs li {
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: var(--color-dark);
    padding: .2em .8em;
    border-bottom: 2px solid transparent;
    border-bottom-color: transparent;
    -webkit-transition: color .2s, border-color .2s;
    transition: color .2s, border-color .2s;
    cursor: pointer;
}

.qf-popOver-box {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    top: 0;
    left: 0;
    text-align: center;
}

.qf-wizzard-container {
    overflow-y: scroll;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 99000;
    background: #fff;
    left: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
}

.qf-wizzard-top-controls {
    position: absolute;
    right: 30px;
}
.qf-wizzard-flex {
    max-width: 1220px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    /*min-height: 536px;*/
    padding: 0 15px;
}

.wizzard-tab-phone {
    min-width: 340px;
    align-self: baseline;
}
.wizzard-tab-content {
    width: 100%;
    display: flex;
    align-items: center;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to
{
    opacity: 0;
}


/* IPHONE STUFF */
.wrapper-phone.template {
    cursor: pointer;
    box-sizing: content-box;
    padding-bottom: 25px;
    width: 108px;
    height: 220px;
    margin: 15px;
}
.wrapper-phone.iphone.template img{
    top: 4px;
    left: 5px;
    width: 195px;
    height: auto;
    border-radius: 20px;
}
.wrapper-phone.iphone.template:after {
    background-size: 108px;
}
.wrapper-phone.template h3 span {
    background: #2b2b30;
    padding: 4px 10px 3px;
    border-radius: 50px;
    color: #fff;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}
.wrapper-phone.template h3 {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: 0;
    font-size: 13px;
    display: flex;
    justify-content: center;
}
.cards-grid{
    display: flex;
    flex-wrap: wrap;
    margin-top: -20px;
}
.card-designs {
    transition: all .3s ease;
}
.right-modal + .eco-content-container .card-designs{
    margin: 0 30vw 0 auto;
}
cards-grid{
    display: flex;
    flex-wrap: wrap;
}
.card-designs {
    transition: all .3s ease;
}
.right-modal + .eco-content-container .card-designs{
    margin: 0 30vw 0 auto;
}
/*
.cards-grid:not(.active),
.cards-grid.active ~ .card-designs{
    display: none;
}
*/
.wrapper-phone {
    width: 432px;
    height: 878px;
    overflow: hidden;
    position: relative;
    box-sizing: content-box;
    width: 216px;
    height: 439px;
    margin: 0 auto;
}
.iphone:after {
    content: '';
    background-image: url('../../assets/images/iphone-wrapper.png');
    background-repeat: no-repeat;
    background-size: 216px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
}
.iphone.template::after {
    pointer-events: auto;
}
.iphone > iframe,
.iphone img{
    position: relative;
    transform: scale(0.5);
    transform-origin: 0 0;
    box-sizing: content-box;
    top: 9px;
    left: 11px;
    width: 387px;
    height: 834px;
    /*background: #000;*/
}
.sc06.wrapper-phone{
    width: 262px;
    height: 536px;
}
.sc06.iphone:after{
    background-size: 260px;
}
.sc06.iphone > iframe,
.sc06.iphone img{
    transform: scale(0.6);
    top: 11px;
    left: 14px;
    width: 386px;
    height: 836px;
}
.template > svg {
    position: absolute;
    z-index: 2;
    font-size: 48px;
    color: var(--color-secondary);
    top: calc(50% - 24px - 20px);
    right: calc(50% - 24px);
    background: #fff;
    border-radius: 50%;
    border: 3px solid #ffffff;
    display: block;
}
.iphone::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 3px solid #efa633;
    border-right: 3px solid transparent;
    animation: spinner .6s linear infinite;
    z-index: 0;
}
@keyframes spinner {
    to {
        transform: rotate(360deg)
    }
}
ul.v-tabs {
    list-style: none;
    padding: 0;
    margin: 0;
    border-left: 3px solid #f1f1f1;
}

li.v-tab-item {
    cursor: pointer;
    border-left: 3px solid transparent;
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 14px;
    margin-left: -3px;
}

li.v-tab-item.active-tab {
    border-color: #eea632;
}
.wizzard-buttons {
    padding: 0 15px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin-left: 20px;
}

.wizzard-buttons button + button,
.wizzard-buttons button + div.has-tooltip{
    margin: 10px 0 0 !important;
}
.qf-wizzard.h100 {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
}


.step-templates::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

.step-templates::-webkit-scrollbar-track {
  background: none;        /* color of the tracking area */
}

.step-templates::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 1px solid var(--color-primary);  /* creates padding around scroll thumb */
}


.step-templates {
  scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: var(--color-primary) rgba(0,0,0,0);   /* scroll thumb and track */
}
.flex-v-center{
    display: flex;
    align-items: center;
}

.promo-stripe {
    width: 100%;
    text-align: center;
    background: var(--color-primary);
    top: 50px;
    font-size: 0.75em;
    padding: 5px 0;
    z-index: 10;
}


@media screen and (max-width: 768px) {
    .wizzard-tab-phone {
        display: none;
    }
}
@media screen and (max-width: 568px) {
    .v-tabs.hPhone,
    .wizzard-tab-phone {
        display: none;
    }
    .wizzard-buttons{
        position: relative;
        margin: 0;
        padding: 0 0 30px;
    }
    .wizard-header-container{
        position: sticky;
        top: 0;
        z-index: 99;
    }
    .row.h100{
        height: auto;
    }
}
</style>
